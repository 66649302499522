import React, { useState } from "react";
import { Answer, Survey } from "../interfaces";
import { postSurveyService } from "../service";
import "./Survey.css";

type SurveyProps = {
  survey: Survey;
  onBtnBack?: () => void;
};
function ModalSurvey({ survey, onBtnBack }: SurveyProps) {
  const [userAnswer, setUserAnswer] = useState<Answer[]>([]);

  const handleAnswer = (e: any) => {
    if (e.target.checked) {
      const newAnswers = [...userAnswer];
      newAnswers.push({ answer: e.target.value });
      setUserAnswer(newAnswers);
    } else {
      setUserAnswer(
        userAnswer.filter((item) => item.answer !== e.target.value)
      );
    }
  };
  console.log(userAnswer);
  const handleSubmitAnswer = async () => {
    try {
      await postSurveyService({
        surveyId: 1,
        questions: [
          {
            question: survey.questions[0].question,
            answer: userAnswer,
          },
        ],
      });
    } catch (e) {
      console.log(e);
    }
    onBtnBack && onBtnBack();
  };
  return (
    <div className="modal_survey_container">
      <div className="modal_survey_content">
        <div className="modal_survey_question">
          {survey.questions[0].question}
        </div>
        <div className="modal_survey_answer">
          {JSON.parse(survey.questions[0].answerOption).map(
            (answer: string, index: number) => {
              return (
                <div key={index}>
                  <input
                    type="checkbox"
                    value={answer}
                    name="answer"
                    id={answer}
                    onChange={(e) => {
                      handleAnswer(e);
                    }}
                    style={{
                      display: "none",
                    }}
                  />
                  <label
                    htmlFor={answer}
                    className="modal_survey_answer_option answer_checked"
                  >
                    <div className="modal_survey_answer_content">{`${String.fromCharCode(
                      65 + index
                    )}. ${answer}`}</div>
                  </label>
                </div>
              );
            }
          )}
        </div>
        {userAnswer.length > 0 && (
          <div className="modal_survey_des">Cảm ơn câu trả lời của bạn.</div>
        )}

        <div className="modal_survey_group_button">
          {userAnswer.length > 0 && (
            <div
              className="modal_survey_btn1 modal_survey_btn"
              onClick={handleSubmitAnswer}
            >
              Hoàn thành
            </div>
          )}

          <div
            className="modal_survey_btn2 modal_survey_btn"
            onClick={onBtnBack}
          >
            Quay lại
          </div>
        </div>
      </div>
    </div>
  );
}

export { ModalSurvey };
