import React, { useEffect, useState } from "react";
import "./index.css";
import { getHistoriesService } from "./service";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getHistoryMessage } from "./constants";

const settings: Settings = {
  className: "history",
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  vertical: true,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  pauseOnHover: false,
};

function Histories() {
  const [indexHighlight, setIndexHighlight] = useState(1);
  const [histories, setHistories] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const correctData = await handleMakeCorrectData();
      setHistories(correctData);
    })();

    const intervalId = setInterval(async () => {
      const latestHistories = await handleMakeCorrectData();
      if (
        latestHistories.length <= 4 &&
        JSON.stringify(latestHistories) !== JSON.stringify(histories)
      ) {
        setHistories(latestHistories);
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  function handleGetNextHighLight(nextSlide: number = 0) {
    const isLastItem = nextSlide === histories.length - 1;
    setIndexHighlight(isLastItem ? 0 : ++nextSlide);
  }

  async function handleMakeCorrectData() {
    const histories = await getHistoriesService();
    const data = await getHistoryMessage(histories);
    if (data.length === 1) {
      return [...data, ...data, ...data, ...data];
    }
    if (data.length === 2) {
      return [...data, ...data];
    }
    if (data.length === 3) {
      return [...data, data[0]];
    }
    return data;
  }

  return (
    <div className="history-container">
      <div className="title">Người chơi gần đây</div>
      <div className="items-container">
        <div>
          <Slider
            {...settings}
            beforeChange={(_currentSlide, nextSlide) => {
              handleGetNextHighLight(nextSlide);
            }}
          >
            {histories.map((history, index) => {
              const isLatest = indexHighlight === index;
              return (
                <div key={index} className="item-wrapper">
                  <div className={isLatest ? "item_highlight" : "item"}>
                    {history}
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export { Histories };
