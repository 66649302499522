import { API_GET_HISTORIES } from "../../constans/apis";
import { execute } from "../../libs";
import { IHistory, IHistoryResponse } from "./interfaces";

export async function getHistoriesService() {
  try {
    return (
      (await execute.get<IHistoryResponse>(API_GET_HISTORIES)).data.response ||
      []
    );
  } catch (error) {
    console.error(error);
    return [] as IHistory[];
  }
}
