import {
  API_GET_IS_SURVEY,
  API_GET_RESULT,
  API_GET_SURVEY,
  API_GET_TURN,
  API_POST_SURVEY,
} from "../../constans/apis";
import { execute } from "../../libs";
import {
  AnswerSurvey,
  IsSurveyRes,
  ResultCbbRes,
  ResultSurvey,
  TurnCbbRes,
} from "./interfaces";

export async function getNumSpinsCbbService() {
  return (
    (await execute.get<TurnCbbRes>(API_GET_TURN)).data.response?.numSpinsCbb ||
    0
  );
}

export async function getResultGiftCodeService() {
  return (await execute.get<ResultCbbRes>(API_GET_RESULT)).data?.response?.code;
}

export async function checkIsNotSurvey() {
  const data = (await execute.get<IsSurveyRes>(API_GET_IS_SURVEY)).data;
  return !data.data || data.data.length <= 0;
}

export async function getSurveyService() {
  return (await execute.get<ResultSurvey>(API_GET_SURVEY)).data.data;
}

export async function postSurveyService(data: AnswerSurvey) {
  return await execute.post(API_POST_SURVEY, data);
}
