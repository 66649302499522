import React, { ComponentPropsWithRef } from "react";
import "./index.css";

type HeaderProps = {
  title: string;
  isHiddenIcons?: boolean;
  onBack?: () => void;
  onSound?: () => void;
  onQuestion?: () => void;
  isOpenSound?: boolean;
} & ComponentPropsWithRef<"div">;
function index({
  title,
  isHiddenIcons,
  onBack,
  onSound,
  onQuestion,
  children,
  isOpenSound,
}: HeaderProps) {
  return (
    <div className="layout_container">
      <div className="layout_header">
        <div className="layout_header_left">
          <img src="/assets/icons/arrow.png" alt="arrow" onClick={onBack} />
          <p>{title}</p>
        </div>
        {!isHiddenIcons && (
          <div className="layout_header_right">
            <div
              style={{
                width: "24px",
                height: "24px",
                marginRight: "5px",
                position: "relative",
              }}
            >
              <img
                onClick={onSound}
                src="/assets/icons/volume.png"
                alt="volume"
                style={{}}
              />
              <div
                onClick={onSound}
                style={{
                  width: "21px",
                  height: "2px",
                  backgroundColor: "#759b2d",
                  borderRadius: "2px",
                  opacity: isOpenSound ? "0" : "1",
                  position: "absolute",
                  top: 11,
                  left: 2,
                  transform: "rotate(130deg)",
                }}
              ></div>
            </div>
            <img
              src="/assets/icons/question.png"
              alt="question"
              onClick={onQuestion}
            />
          </div>
        )}
      </div>
      <div className="layout_content">{children}</div>
    </div>
  );
}

export default index;
