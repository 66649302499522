import { Settings } from "react-slick";
import { GiftCode } from "../wheel/interfaces";
import { IHistory } from "./interfaces";

export const HISTORY_MESSAGE: Record<GiftCode, string> = {
  CBB001: "vừa tham gia quay",
  CBB002: "đã quay trúng Thẻ cào 20.000đ",
  CBB003: "đã quay trúng 5 triệu",
  CBB004: "đã quay trúng 10 triệu",
  CBB005: "đã quay trúng 20 triệu",
  CBB006: "đã quay trúng 100 triệu",
};

export function getHistoryMessage(data: IHistory[]) {
  return data.map(
    ({ username, code }) => username + " " + HISTORY_MESSAGE[code]
  );
}

export const HISTORY_CAROUSEL_SETTINGS: Settings = {
  className: "history",
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  vertical: true,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
};
