import React from "react";
import "./index.css";

type Variant = "success" | "fail";
type PopupProps = {
  thumbnail?: string;
  title: string;
  description: React.ReactNode;
  variant: Variant;
  text1?: string;
  text2?: string;
  onBntOneClick: () => void;
  onBntTwoClick: () => void;
};
function index({
  text1,
  text2,
  thumbnail,
  title,
  description,
  variant,
  onBntOneClick,
  onBntTwoClick,
}: PopupProps) {
  const isFailVariant = variant === "fail";
  return (
    <div className="popup">
      <img
        src={thumbnail || "https://picsum.photos/200/300"}
        alt="thumbnail"
        className="thumbnail"
      />
      <p
        className="title"
        style={{
          fontWeight: 600,
          fontSize: "14px",
          color: isFailVariant ? "#FF5D4F" : "#438303",
          margin: "8px 0px",
        }}
      >
        {title}
      </p>
      <p className="description">{description}</p>
      <ActionBtn
        text1={text1}
        text2={text2}
        isFailVariant={isFailVariant}
        onBntOneClick={onBntOneClick}
        onBntTwoClick={onBntTwoClick}
      />
    </div>
  );
}

type ActionProps = { isFailVariant: boolean } & Pick<
  PopupProps,
  "onBntOneClick" | "onBntTwoClick" | "text1" | "text2"
>;
function ActionBtn({
  isFailVariant,
  text1,
  text2,
  onBntOneClick,
  onBntTwoClick,
}: ActionProps) {
  const finalText1 = text1 || (isFailVariant ? "ĐỔI MUỖNG" : "ĐỔI QUÀ");
  const finalText2 = text2 || (isFailVariant ? "THOÁT" : "CHƠI TIẾP");
  return (
    <div className="btn-container">
      <div className="btn-action btn-action-outline" onClick={onBntOneClick}>
        {finalText1}
      </div>
      <div
        className={
          isFailVariant ? "btn-action-fail" : "btn-action btn-action-solid"
        }
        style={{
          color: isFailVariant ? "#438303" : "#fff",
        }}
        onClick={onBntTwoClick}
      >
        {finalText2}
      </div>
    </div>
  );
}
export default index;
