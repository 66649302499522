import { Gift, GiftCode } from "./interfaces";

export const TIME_WHEEL = 15; // by second
export const TOTAL_GIFT = 6;
export const PREFIX_MOBILE_URL = "/mobile-view";
export const GIFT_DATA: Record<GiftCode, Gift> = {
  CBB001: {
    title: "Chúc bạn may mắn lần sau!",
    description:
      "Rất tiếc, bạn không trúng giải thưởng. Hãy tiếp tục tham gia để có cơ hội may mắn nhé!",
    thumbnail: "/assets/results/1.png",
    status: "fail",
    indexOnWheel: 6,
    text1: "THỬ LẠI",
    text2: "THOÁT",
    onBntTwoClick() {
      window.location.replace(PREFIX_MOBILE_URL + "/mini-game");
    },
  },
  CBB002: {
    title: "Chúc mừng bạn",
    description: <Description label="Giải Khuyến Khích" />,
    thumbnail: "/assets/results/2.png",
    status: "success",
    indexOnWheel: 5,
    onBntOneClick() {
      window.location.replace(PREFIX_MOBILE_URL + "/my-reward?tab=e-voucher");
    },
  },
  CBB003: {
    title: "Chúc mừng bạn",
    description: <Description label="Giải Ba" />,
    thumbnail: "/assets/results/3.png",
    status: "success",
    indexOnWheel: 4,
    onBntOneClick() {
      window.location.replace(PREFIX_MOBILE_URL + "/my-reward?tab=gift");
    },
  },

  CBB004: {
    title: "Chúc mừng bạn",
    description: <Description label="Giải Nhì" />,
    thumbnail: "/assets/results/4.png",
    status: "success",
    indexOnWheel: 3,
    onBntOneClick() {
      window.location.replace(PREFIX_MOBILE_URL + "/my-reward?tab=gift");
    },
  },

  CBB005: {
    title: "Chúc mừng bạn",
    description: <Description label="Giải Nhất" />,
    thumbnail: "/assets/results/5.png",
    status: "success",
    indexOnWheel: 2,
    onBntOneClick() {
      window.location.replace(PREFIX_MOBILE_URL + "/my-reward?tab=gift");
    },
  },

  CBB006: {
    title: "Chúc mừng bạn",
    description: <Description label="Giải Vô Địch Miễn Dịch" />,
    thumbnail: "/assets/results/6.png",
    status: "success",
    indexOnWheel: 1,
    onBntOneClick() {
      window.location.replace(PREFIX_MOBILE_URL + "/my-reward?tab=gift");
    },
  },
};

export const TURN_OUT_DATA: Gift = {
  title: "Bạn không đủ lượt quay!",
  status: "fail",
  description:
    "Bạn hãy đổi muỗng sản phẩm ColosBaby để có cơ hội tham gia và nhận hàng ngàn phần quà hấp dẫn từ chương trình Vòng Quay May Mắn ColosBaby Vô Địch Miễn Dịch nhé!",
  thumbnail: "/assets/results/girl_2.png",
  onBntOneClick() {
    window.location.replace(PREFIX_MOBILE_URL + "/scan-qr");
  },
  onBntTwoClick() {
    window.location.replace(PREFIX_MOBILE_URL + "/mini-game");
  },
};

export const ERROR_DATA: Gift = {
  title: "Lỗi hệ thống",
  status: "fail",
  description: "Bạn vui lòng thử lại sau!",
  thumbnail: "/assets/results/1.png",
  onBntOneClick() {
    window.location.replace(PREFIX_MOBILE_URL + "/scan-qr");
  },
  onBntTwoClick() {
    window.location.replace(PREFIX_MOBILE_URL + "/mini-game");
  },
};

export const SURVEY_ALERT: Gift = {
  title: "Vòng lặp chưa được phá vỡ",
  description: (
    <>
      Đừng lo mẹ ơi, hãy thực hiện{" "}
      <span
        style={{
          fontWeight: 700,
          color: "#000",
          fontSize: "12px",
          textTransform: "uppercase",
        }}
      >
        KHẢO SÁT
      </span>{" "}
      để thêm lượt chơi và cơ hội trúng những phần quà có giá trị khác!
    </>
  ),
  thumbnail: "/assets/results/girl_2.png",
  status: "fail",
  indexOnWheel: 6,
  text1: "KHẢO SÁT NGAY",
  text2: "QUAY LẠI",
  openModalSurvey: true,
};

function Description({ label }: { label: string }) {
  return (
    <p>
      Bạn đã nhận được <span style={{ fontWeight: "bold" }}>{label}</span>. Vui
      lòng vào mục <span style={{ fontWeight: "bold" }}>"Quà của tôi"</span> để
      kiểm tra. Để biết thêm thông tin vui lòng liên hệ
      <span style={{ fontWeight: "bold" }}> Hotline 1900633559</span>.
    </p>
  );
}
