import "./index.css";

function Policy() {
  return (
    <div className="policy">
      <h3 className="policy-header">CHÍNH SÁCH GIAO HÀNG</h3>
      <div className="policy-content">
        <div className="policy_item">
          <p className="policy_item-title">
            I. Tất cả các đơn hàng của khách hàng đều được miễn phí giao hàng
            nếu khách hàng không phạm phải hai lỗi sau:
          </p>
          <p className="policy_item-description">
            - Từ chối nhận hàng 3 lần trên cùng một đơn(Khách hàng sẽ không được
            phép đặt lại đơn hàng đó).
          </p>
          <p className="policy_item-description">
            - Từ chối nhận hàng 3 đơn liên tiếp: Khách hàng sẽ phải trả phí vận
            chuyển cho những đơn hàng tiếp theo.
          </p>
        </div>
        <div className="policy_item">
          <p className="policy_item-title">
            II. Chính sách hoàn xu đối với những đơn giao không hành công:
          </p>
          <p className="policy_item-description">
            - Những đơn giao không thành công vì lí do bất khả kháng(thiên tai,
            dịch bệnh,...) : Khách hàng sẽ được hoàn xu 100%.
          </p>
          <p className="policy_item-description">
            - Những đơn giao không thành công vì lí do khách hàng không nhận
            hàng: Khách hàng có thể chọn hoàn xu, và khách hàng sẽ chỉ được hoàn
            90% xu trên tổng số xu đơn hàng
          </p>
        </div>
        <div className="policy_item">
          <p className="policy_item-title">
            III. Khi nhận được quà, khách hàng lưu ý mở app để xác nhận giao
            thành công. Nếu trong vòng 7 ngày từ khi nhận hàng, khách hàng không
            xác nhận đơn hàng, hệ thống sẽ tự động chuyển đơn hàng sang trạng
            thái "Đã hoàn thành".
          </p>
        </div>
        <div className="policy_item">
          <p className="policy_item-title">IV. Thời gian vận chuyển:</p>
          <p className="policy_item-description">
            Khoảng 7-10 ngày sau khi đơn hàng đã tiếp nhận. Tuy nhiên vì tình
            hình dịch bệnh nên đơn hàng có thể sẽ đến trễ hơn dự kiến.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Policy;
