import React, { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../hooks/useAppContext";
import { Histories } from "../histories";
import Layout from "../layouts";
import Modal from "../modal";
import Popup from "../popup";
import {
  ERROR_DATA,
  GIFT_DATA,
  PREFIX_MOBILE_URL,
  SURVEY_ALERT,
  TIME_WHEEL,
  TOTAL_GIFT,
  TURN_OUT_DATA,
} from "./constants";
import "./index.css";
import { Gift, GiftCode, Survey } from "./interfaces";
import {
  checkIsNotSurvey,
  getNumSpinsCbbService,
  getResultGiftCodeService,
  getSurveyService,
} from "./service";
import { ModalSurvey } from "./components/SurveyModal";

let audio: HTMLAudioElement | null = null;
function Wheel() {
  const { setCurrentScreen } = useAppContext();
  const layer2Ref = useRef<HTMLDivElement>(null);
  const [isOpenModal, setOpenModal] = useState(false);
  const [result, setResult] = useState<Gift>(GIFT_DATA["CBB001"]);
  const [turnLeft, setTurnLeft] = useState(0);
  const [isCreatedAudio, setIsCreatedAudio] = useState(false);
  const [toggleSound, setToggleSound] = useState(true);
  const [currentTimeOutId, setCurrentTimeOutId] = useState<any>(null);
  const [survey, setSurvey] = useState<Survey>({} as Survey);
  const [openModalSurvey, setOpenModalSurvey] = useState(false);
  const [isSpinning, setSpinning] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token") || "abc";

  useEffect(() => {
    audio = new Audio("/assets/background.mp3");
    audio.preload = "metadata";
    audio.currentTime = 2;
    audio.loop = true;

    return () => {
      resetAll();
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("token", token);
  }, [token]);

  useEffect(() => {
    (async () => {
      try {
        const turnLeft = await getNumSpinsCbbService();
        setTurnLeft(turnLeft);
      } catch (error) {
        setTurnLeft(1);
      }
    })();
  }, [isOpenModal]);

  function resetAll() {
    currentTimeOutId && clearTimeout(currentTimeOutId);
    audio?.pause();
    setToggleSound(true);
    setIsCreatedAudio(false);
    setTurnLeft(0);
    setOpenModal(false);
    setOpenModalSurvey(false);
    setResult(GIFT_DATA["CBB001"]);
  }

  function handleSpin() {
    if (!localStorage.getItem("token")) return;
    if (!checkHaveTurn()) return;
    if (isSpinning) return;
    if (!isCreatedAudio) handleCreateAudio();
    const layer2El = layer2Ref.current;

    if (!layer2El) return;
    handleCalculateReg(layer2El);
  }

  async function handleCalculateReg(element: HTMLDivElement) {
    let giftCode: GiftCode = "CBB001";
    let gift: Gift = GIFT_DATA[giftCode];
    try {
      giftCode = await getResultGiftCodeService();
      const isNotSurvey = await checkIsNotSurvey();
      if (isNotSurvey && giftCode === "CBB001") {
        const survey = await getSurveyService();
        setSurvey(survey);
        gift = SURVEY_ALERT;
      } else {
        gift = GIFT_DATA[giftCode] || GIFT_DATA["CBB001"];
      }
      if (gift) setResult(gift);
      else {
        handleShowError();
        return;
      }
    } catch (error) {
      console.log(error);
      handleShowError();
      return;
    }
    const result = gift.indexOnWheel || 1; //default is CBB001
    const offsetDeg = 360 / TOTAL_GIFT / 2;
    const extraTimeOfSpin = 360 * 5;
    const degByResult = (360 / 6) * result;
    const finalDeg = degByResult - offsetDeg + extraTimeOfSpin;

    element.style.transitionDuration = `${TIME_WHEEL}s`;
    element.style.transitionTimingFunction =
      "cubic-bezier(0.215, 0.610, 0.0355, 1.03)";
    element.style.transform = `rotate(${finalDeg}deg)`;
    setSpinning(true);

    const timeOutId = setTimeout(() => {
      setOpenModal(true);
      setSpinning(false);
    }, (TIME_WHEEL + 1) * 1000);
    setCurrentTimeOutId(timeOutId);
  }

  function handleShowError() {
    setResult(ERROR_DATA);
    setOpenModal(true);
  }

  function checkHaveTurn() {
    if (turnLeft === 0) {
      setResult(TURN_OUT_DATA);
      setOpenModal(true);
      return false;
    }
    return true;
  }

  function handleReset() {
    const layer2El = layer2Ref.current;
    if (!layer2El) return;
    layer2El.style.transform = "unset";
    layer2El.style.transitionDuration = "unset";
    layer2El.style.transitionTimingFunction = "unset";

    setOpenModal(false);
    setOpenModalSurvey(false);
  }

  function handleCreateAudio() {
    setToggleSound(true);
    setIsCreatedAudio(true);
    audio?.play();
  }

  function toggleAudio() {
    if (!audio || !isCreatedAudio) return;
    !toggleSound ? audio.play() : audio.pause();
    setToggleSound(!toggleSound);
  }

  return (
    <Layout
      title="Giải trí"
      onSound={toggleAudio}
      onQuestion={() => setCurrentScreen("term-condition")}
      onBack={() => window.location.replace(PREFIX_MOBILE_URL + "/mini-game")}
      isOpenSound={toggleSound}
    >
      <div
        style={{
          height: "100%",
          overflow: "scroll",
        }}
      >
        <div className="container">
          <img src="/assets/colosbaby.png" alt="colosbaby" className="colos" />
          <div className="main">
            <img
              src="/assets/vo-dich.png"
              alt="champion"
              className="champion"
            />
            <img src="/assets/mien-dich.png" alt="immune" className="immune" />
            <div className="layer1">
              <img src="/assets/needle.png" alt="needle" className="needle" />
              <img
                src="/assets/layer-2.png"
                alt="layer2"
                className="layer2"
                // @ts-ignore
                ref={layer2Ref}
              />
            </div>
          </div>
          <button className="btn-start" onClick={handleSpin}></button>
          <p className="turn-left">Số lượt: {turnLeft}</p>
          <Histories />
          <Modal isOpen={isOpenModal}>
            {!openModalSurvey ? (
              <Popup
                text1={result.text1}
                text2={result.text2}
                title={result.title}
                description={<Description>{result.description}</Description>}
                thumbnail={result.thumbnail}
                variant={result.status}
                onBntOneClick={() => {
                  if (result.openModalSurvey) {
                    setOpenModalSurvey(true);
                  } else {
                    result.onBntOneClick && result.onBntOneClick();
                    handleReset();
                  }
                }}
                onBntTwoClick={() => {
                  result.onBntTwoClick && result.onBntTwoClick();
                  handleReset();
                }}
              />
            ) : (
              <ModalSurvey
                survey={survey}
                onBtnBack={() => {
                  handleReset();
                }}
              />
            )}
          </Modal>
        </div>
      </div>
    </Layout>
  );
}

function Description({ children }: { children: React.ReactNode }) {
  return (
    <div style={{ fontSize: "12px", textAlign: "center" }}>{children}</div>
  );
}

export default Wheel;
