import React, { useLayoutEffect, useState } from "react";
import Policy from "./components/policy";
import TermCondition from "./components/term-conditions";
import Wheel from "./components/wheel";
import { useAppContext } from "./hooks/useAppContext";

function App() {
  const { currentScreen } = useAppContext();
  const [finalPage, setFinalPage] = useState(currentScreen);

  useLayoutEffect(() => {
    if (window.location.hash === "#policies") {
      setFinalPage("policies");
    } else setFinalPage(currentScreen);
  }, [currentScreen]);

  function renderCurrentScreen() {
    switch (finalPage) {
      case "home":
        return <Wheel />;
      case "term-condition":
        return <TermCondition />;
      case "policies":
        return <Policy />;
      default:
        return <Wheel />;
    }
  }
  return renderCurrentScreen();
}

export default App;
