import { ComponentPropsWithRef } from "react";
import "./index.css";
import { Portal } from "./Portal";

const Modal = ({
  children,
  isOpen,
}: ComponentPropsWithRef<"div"> & { isOpen: boolean }) => {
  return isOpen ? (
    <Portal>
      <ModalContainer>{children}</ModalContainer>
    </Portal>
  ) : null;
};

function ModalContainer({ children }: ComponentPropsWithRef<"div">) {
  return (
    <div className="modal-container">
      <div className="overlay">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
}
export default Modal;
