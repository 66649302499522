import React, { useEffect } from "react";
import { useAppContext } from "../../hooks/useAppContext";
import Layout from "../layouts";
import "./index.css";

function TermCondition() {
  const { setCurrentScreen } = useAppContext();

  return (
    <Layout
      title="Điều khoản"
      isHiddenIcons
      onBack={() => setCurrentScreen("home")}
    >
      <div className="term">
        <p className="term__title">I. ĐIỀU KHOẢN MINIGAME</p>
        <p className="mb-15px">
          Khách hàng sử dụng sản phẩm sữa bột Colosbaby bao gồm: ColosBaby
          (600IgG), ColosBaby Gold, ColosBaby IQ Gold, ColosBaby Bio Gold (không
          bao gồm ColosBaby Gold for MUM) sẽ nhận được{" "}
          <span className="mark">1 lượt quay</span> với mỗi lần{" "}
          <span className="mark">đổi muỗng tích xu thành công.</span> Với tỷ lệ
          quy đổi 1 lon 800gr/900gr = 2 lon 400gr = 1 lượt quay may mắn của vòng
          quay <span className="mark">Colosbaby Vô Địch Miễn Dịch.</span>
        </p>
        <p className="mb-15px">
          <span className="mark">Một ngày tối đa 3 lượt quay</span> với các giải
          thưởng sau:
        </p>

        <p>
          <span className="mark">Giải Vô Địch Miễn Dịch: Tiền mặt:</span> Trị
          giá 100.000.000 VNĐ
        </p>
        <p>
          <span className="mark">Giải Nhất: Tiền mặt:</span> Trị giá 20.000.000
          VNĐ
        </p>
        <p>
          <span className="mark">Giải Nhì: Tiền mặt:</span> Trị giá 10.000.000
          VNĐ
        </p>
        <p>
          <span className="mark">Giải Ba: Tiền mặt:</span> Trị giá 5.000.000 VNĐ
        </p>
        <p>
          <span className="mark">Giải Khuyến Khích: Thẻ cào Điện Thoại:</span>{" "}
          Trị giá 20.000 VNĐ
        </p>

        {/* II. THÔNG TIN NHẬN GIẢI */}
        <p className="term__title">II. THÔNG TIN NHẬN GIẢI</p>
        <p className="mark mb-15px">
          1. Thông tin nhận giải Vô Địch Miễn Dịch, Giải Nhất, Giải Nhì, Giải
          Ba:
        </p>
        <p className="mb-15px">
          * Khách hàng sẽ chụp hình CMND và mã muỗng quay trúng thưởng gửi Nhân
          Viên Chăm Sóc Khách Hàng của công ty VitaDairy. Công ty sẽ tiến hành
          trao thưởng trực tiếp cho khách hàng tại địa chỉa khách hàng cung cấp.
        </p>
        <p className="mb-15px">
          * Khi nhận thưởng, khách hàng cần xuất trình các giấy tờ sau để nhận
          giải thưởng : Bản sao CMND/ Căn cước công dân/ Hộ Chiếu và Muỗng trúng
          thưởng.
        </p>
        <p className="mb-15px">
          * Nếu khách hàng không cung cấp đầy đủ thông tin hợp lệ, VitaDairy có
          quyền từ chối trao giải thưởng theo quy định.
        </p>
        <p className="mark mb-15px">2. Thông tin nhận giải khuyến khích: </p>
        <p className="mb-15px">
          Khách hàng nhận thưởng trực tiếp tại ứng dụng bằng cách : Nạp tiền
          trực tiếp vào điện thoại hoặc lấy thẻ cào qua tin nhắn.
        </p>
      </div>
    </Layout>
  );
}

export default TermCondition;
