import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
type Screen = "home" | "term-condition" | "policies";
export interface AppI {
  currentScreen?: Screen;
  setCurrentScreen: (screen: Screen) => void;
}
const UserContext = createContext<AppI>({
  currentScreen: "home",
  setCurrentScreen: (_screen: Screen) => {},
});

export const AppProvider = ({
  children,
}: {
  children: ReactNode;
  value: AppI;
}) => {
  const [currentScreen, setCurrentScreen] = useState<Screen>("home");
  const contextValue: AppI = {
    currentScreen,
    setCurrentScreen: useCallback(
      (screen: Screen) => setCurrentScreen(screen),
      []
    ),
  };
  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export const useAppContext = () => {
  const { currentScreen, setCurrentScreen } = useContext(UserContext);
  return { currentScreen, setCurrentScreen };
};
